<template>
  <i @click="handleClick">
    <component
      :is="svg"
      :width="computedSize.width"
      :height="computedSize.height"
      class="leading-none"
      stroke="currentColor"
      :fill="fill ? 'currentColor' : undefined"
    />
  </i>
</template>

<script>
export const icons = {
  arrowUp: require('@/assets/images/icons/arrow-up.svg?inline'),
  arrowDown: require('@/assets/images/icons/arrow-down.svg?inline'),
  arrowLeft: require('@/assets/images/icons/arrow-left.svg?inline'),
  arrowRight: require('@/assets/images/icons/arrow-right.svg?inline'),
  apple: require('@/assets/images/icons/os-system-apple.svg?inline'),
  android: require('@/assets/images/icons/android-1.svg?inline'),
  web: require('@/assets/images/icons/monitor.svg?inline'),
  add: require('@/assets/images/icons/add.svg?inline'),
  address: require('@/assets/images/icons/pin-location-1.svg?inline'),
  subtract: require('@/assets/images/icons/subtract.svg?inline'),
  accordion: require('@/assets/images/icons/move-expand-vertical.svg?inline'),
  allowInternet: require('@/assets/images/icons/network-user.svg?inline'),
  archive: require('@/assets/images/icons/archive.svg?inline'),
  attendees: require('@/assets/images/icons/multiple-circle.svg?inline'),
  calendar: require('@/assets/images/icons/calendar.svg?inline'),
  closePanel: require('@/assets/images/icons/remove.svg?inline'),
  changeCustomer: require('@/assets/images/icons/single-neutral-actions-sync.svg?inline'),
  check: require('@/assets/images/icons/check-1.svg?inline'),
  cross: require('@/assets/images/icons/remove.svg?inline'),
  communications: require('@/assets/images/icons/conversation-chat-1.svg?inline'),
  creditCard: require('@/assets/images/icons/credit-card-dollar-1.svg?inline'),
  customerAdd: require('@/assets/images/icons/single-neutral-actions-add.svg?inline'),
  customerEdit: require('@/assets/images/icons/single-neutral-actions-edit-1.svg?inline'),
  customerMerge: require('@/assets/images/icons/diagram-fall-down.svg?inline'),
  customerResetPass: require('@/assets/images/icons/synchronize-arrows-lock.svg?inline'),
  customFields: require('@/assets/images/icons/list-bullets.svg?inline'),
  datepicker: require('@/assets/images/icons/calendar.svg?inline'),
  delete: require('@/assets/images/icons/bin-2.svg?inline'),
  edit: require('@/assets/images/icons/pencil-2.svg?inline'),
  ellipses: require('@/assets/images/icons/navigation-menu-horizontal.svg?inline'),
  email: require('@/assets/images/icons/envelope.svg?inline'),
  externalLink: require('@/assets/images/icons/expand-6.svg?inline'),
  expand: require('@/assets/images/icons/expand-2.svg?inline'),
  shrink: require('@/assets/images/icons/shrink-1.svg?inline'),
  forms: require('@/assets/images/icons/task-checklist-write.svg?inline'),
  giftCard: require('@/assets/images/icons/products-gift-tag.svg?inline'),
  history: require('@/assets/images/icons/synchronize-arrow-clock.svg?inline'),
  integrations: require('@/assets/images/icons/mood-happy.svg?inline'),
  pass: require('@/assets/images/icons/ticket.svg?inline'),
  placeholder: require('@/assets/images/icons/smile.svg?inline'),
  reservation: require('@/assets/images/icons/calendar-clock.svg?inline'),
  reservationCancel: require('@/assets/images/icons/calendar-block.svg?inline'),
  reservationCheckIn: require('@/assets/images/icons/single-neutral-actions-check-1.svg?inline'),
  reservationConfirm: require('@/assets/images/icons/calendar-check-1.svg?inline'),
  reservationMove: require('@/assets/images/icons/move-to-bottom.svg?inline'),
  reservationNoShow: require('@/assets/images/icons/single-neutral-actions-remove.svg?inline'),
  reservationPreview: require('@/assets/images/icons/calendar-search.svg?inline'),
  reservationRebook: require('@/assets/images/icons/calendar-refresh.svg?inline'),
  reservationClass: require('@/assets/images/icons/multiple-users-1.svg?inline'),
  sale: require('@/assets/images/icons/receipt-slip-1.svg?inline'),
  orderClose: require('@/assets/images/icons/receipt-slip-1.svg?inline'),
  services: require('@/assets/images/icons/cog-hand-give.svg?inline'),
  customerSegments: require('@/assets/images/icons/analytics-pie-2.svg?inline'),
  marketing: require('@/assets/images/icons/advertising-megaphone.svg?inline'),
  menuDashboard: require('@/assets/images/icons/house-chimney-2.svg?inline'),
  menuBookings: require('@/assets/images/icons/time-clock-circle-1.svg?inline'),
  menuSales: require('@/assets/images/icons/tags-double.svg?inline'),
  menuCustomers: require('@/assets/images/icons/multiple-neutral-2.svg?inline'),
  menuRoster: require('@/assets/images/icons/calendar-1.svg?inline'),
  menuMarketing: require('@/assets/images/icons/advertising-megaphone.svg?inline'),
  menuReports: require('@/assets/images/icons/performance-increase-2.svg?inline'),
  menuManage: require('@/assets/images/icons/cog-double-2.svg?inline'),
  menuOnlineBooking: require('@/assets/images/icons/network-settings.svg?inline'),
  menuIntegrations: require('@/assets/images/icons/module-four.svg?inline'),
  menuNotifications: require('@/assets/images/icons/alarm-bell.svg?inline'),
  menuAccount: require('@/assets/images/icons/single-neutral-circle.svg?inline'),
  menuHelp: require('@/assets/images/icons/question-circle.svg?inline'),
  menuHamburger: require('@/assets/images/icons/navigation-menu.svg?inline'),
  menuSearch: require('@/assets/images/icons/search.svg?inline'),
  menuMessages: require('@/assets/images/icons/messages-bubble-square.svg?inline'),
  menuAlerts: require('@/assets/images/icons/alarm-bell.svg?inline'),
  menuSupport: require('@/assets/images/icons/help-wheel.svg?inline'),
  menuNotes: require('@/assets/images/icons/notes-text-flip.svg?inline'),
  menuPlan: require('@/assets/images/icons/check-circle-1.svg?inline'),
  notes: require('@/assets/images/icons/notes-text-flip.svg?inline'),
  currency: require('@/assets/images/icons/currency-dollar-circle.svg?inline'),
  chart: require('@/assets/images/icons/analytics-bars.svg?inline'),
  export: require('@/assets/images/icons/share-2.svg?inline'),
  sms: require('@/assets/images/icons/conversation-chat-2.svg?inline'),
  customer: require('@/assets/images/icons/single-neutral.svg?inline'),
  customerNew: require('@/assets/images/icons/single-neutral-actions-add.svg?inline'),
  filter: require('@/assets/images/icons/filter-1.svg?inline'),
  search: require('@/assets/images/icons/search.svg?inline'),
  searchEnter: require('@/assets/images/icons/keyboard-arrow-return-1.svg?inline'),
  searchArrowDown: require('@/assets/images/icons/arrow-down.svg?inline'),
  searchArrowUp: require('@/assets/images/icons/arrow-up.svg?inline'),
  x: require('@/assets/images/icons/remove.svg?inline'),
  chevronUp: require('@/assets/images/icons/arrow-up-1.svg?inline'),
  chevronDown: require('@/assets/images/icons/arrow-down-1.svg?inline'),
  chevronLeft: require('@/assets/images/icons/arrow-left-1.svg?inline'),
  chevronRight: require('@/assets/images/icons/arrow-right-1.svg?inline'),
  chevronUpDouble: require('@/assets/images/icons/arrow-button-up-1.svg?inline'),
  chevronDownDouble: require('@/assets/images/icons/arrow-button-down-1.svg?inline'),
  chevronLeftDouble: require('@/assets/images/icons/arrow-button-left-1.svg?inline'),
  chevronRightDouble: require('@/assets/images/icons/arrow-button-right-1.svg?inline'),
  refresh: require('@/assets/images/icons/button-refresh-arrows.svg?inline'),
  product: require('@/assets/images/icons/shipment-upload.svg?inline'),
  save: require('@/assets/images/icons/floppy-disk.svg?inline'),
  success: require('@/assets/images/icons/check-circle-1.svg?inline'),
  danger: require('@/assets/images/icons/alert-circle.svg?inline'),
  warning: require('@/assets/images/icons/alert-circle.svg?inline'),
  info: require('@/assets/images/icons/information-circle.svg?inline'),
  successBold: require('@/assets/images/icons/check-circle-1.svg?inline'),
  dangerBold: require('@/assets/images/icons/alert-circle.svg?inline'),
  warningBold: require('@/assets/images/icons/alert-circle.svg?inline'),
  infoBold: require('@/assets/images/icons/information-circle.svg?inline'),
  notification: require('@/assets/images/icons/alarm-bell-ring.svg?inline'),
  circle: require('@/assets/images/icons/sign-badge-circle.svg?inline'),
  print: require('@/assets/images/icons/print-text.svg?inline'),
  company: require('@/assets/images/icons/products-briefcase.svg?inline'),
  phone: require('@/assets/images/icons/phone.svg?inline'),
  homePhone: require('@/assets/images/icons/phone-retro-1.svg?inline'),
  send: require('@/assets/images/icons/send-email.svg?inline'),
  variable: require('@/assets/images/icons/pin.svg?inline'),
  passwordHide: require('@/assets/images/icons/view-off.svg?inline'),
  passwordShow: require('@/assets/images/icons/view-1.svg?inline'),
  view: require('@/assets/images/icons/view-1.svg?inline'),
  drag: require('@/assets/images/icons/direction-button-arrows.svg?inline'),
  resize: require('@/assets/images/icons/resize-icon.svg?inline'),
  reorderHandle: require('@/assets/images/icons/resize-icon.svg?inline'),
  refund: require('@/assets/images/icons/refund-icon.svg?inline'),
  price: require('@/assets/images/icons/money-wallet.svg?inline'),
  sortUnset: require('@/assets/images/icons/data-transfer-vertical.svg?inline'),
  sortAsc: require('@/assets/images/icons/keyboard-arrow-up.svg?inline'),
  sortDesc: require('@/assets/images/icons/keyboard-arrow-down.svg?inline'),
  waitlist: require('@/assets/images/icons/task-list-clock.svg?inline'),
  scheduleOptions: require('@/assets/images/icons/cog-double-2.svg?inline'),
  unlock: require('@/assets/images/icons/lock-unlock.svg?inline'),
  photos: require('@/assets/images/icons/picture-polaroid-human.svg?inline'),
  closePanels: require('@/assets/images/icons/logout-2.svg?inline'),
  selectTileCheck: require('@/assets/images/icons/check-circle-1.svg?inline'),
  selectTileCircle: require('@/assets/images/icons/sign-badge-circle-alternate.svg?inline'),
  schedule: require('@/assets/images/icons/time-clock-circle-1.svg?inline'),
  payment: require('@/assets/images/icons/cash-payment-bag.svg?inline'),
  loyaltyPoints: require('@/assets/images/icons/rating-star-give-1.svg?inline'),
  customerCredit: require('@/assets/images/icons/gauge-dashboard.svg?inline'),
  cash: require('@/assets/images/icons/cash-payment-bill.svg?inline'),
  discount: require('@/assets/images/icons/discount-bubble.svg?inline'),
  charge: require('@/assets/images/icons/tags-add.svg?inline'),
  saleTip: require('@/assets/images/icons/wedding-money-piggy.svg?inline'),
  resetPass: require('@/assets/images/icons/synchronize-arrows-lock.svg?inline'),
  permission: require('@/assets/images/icons/login-keys.svg?inline'),
  resources: require('@/assets/images/icons/hammer-wench.svg?inline'),
  reorder: require('@/assets/images/icons/move-to-bottom.svg?inline'),
  preferences: require('@/assets/images/icons/cog-double-2.svg?inline'),
  copy: require('@/assets/images/icons/common-file-double-1.svg?inline'),
  details: require('@/assets/images/icons/common-file-text-settings.svg?inline'),
  employee: require('@/assets/images/icons/single-neutral.svg?inline'),
  reminder: require('@/assets/images/icons/task-finger-bandage.svg?inline'),
  bufferTime: require('@/assets/images/icons/hourglass-1.svg?inline'),
  tax: require('@/assets/images/icons/discount-1.svg?inline'),
  upload: require('@/assets/images/icons/cloud-upload.svg?inline'),
  minimiseGaps: require('@/assets/images/icons/shrink-vertical.svg?inline'),
  formItems: require('@/assets/images/icons/ui-columns.svg?inline'),
  consent: require('@/assets/images/icons/app-window-password-correct.svg?inline'),
  devices: require('@/assets/images/icons/laptop-settings.svg?inline'),
  backspace: require('@/assets/images/icons/keyboard-delete.svg?inline'),
  pin: require('@/assets/images/icons/password-lock.svg?inline'),
  undo: require('@/assets/images/icons/undo.svg?inline'),
  logout: require('@/assets/images/icons/logout-2.svg?inline'),
  login: require('@/assets/images/icons/login-keys.svg?inline'),
  switch: require('@/assets/images/icons/button-refresh-arrows.svg?inline'),
  summary: require('@/assets/images/icons/analytics-pie-2.svg?inline'),
  applyStocktake: require('@/assets/images/icons/check-circle-1.svg?inline'),
  reservationRecurrence: require('@/assets/images/icons/rotate-forward.svg?inline'),
  markRead: require('@/assets/images/icons/email-action-check.svg?inline'),
  facebook: require('@/assets/images/icons/facebook.svg?inline'),
  twitter: require('@/assets/images/icons/twitter.svg?inline'),
  instagram: require('@/assets/images/icons/instagram.svg?inline'),
  message: require('@/assets/images/icons/messages-bubble-square.svg?inline'),
  sync: require('@/assets/images/icons/database-sync.svg?inline'),
  loading: require('@/assets/images/icons/synchronize-arrows.svg?inline'),
  cancel: require('@/assets/images/icons/calendar-block.svg?inline'),
  building: require('@/assets/images/icons/shop-1.svg?inline'),
  users: require('@/assets/images/icons/multiple-neutral-2.svg?inline'),
  user: require('@/assets/images/icons/single-neutral.svg?inline'),
  dollar: require('@/assets/images/icons/money-wallet.svg?inline'),
  menu: require('@/assets/images/icons/navigation-menu-3.svg?inline'),
  receive: require('@/assets/images/icons/receive.svg?inline'),
  widget: require('@/assets/images/icons/programming-browser-1.svg?inline'),
  review: require('@/assets/images/icons/messages-bubble-square-star.svg?inline'),
  gallery: require('@/assets/images/icons/picture-polaroid-human.svg?inline'),
  businessHours: require('@/assets/images/icons/time-clock-circle-1.svg?inline'),
  roster: require('@/assets/images/icons/calendar-1.svg?inline'),
  campaignOpened: require('@/assets/images/icons/check-email.svg?inline'),
  campaignUnopened: require('@/assets/images/icons/disable-email.svg?inline'),
  campaignClicked: require('@/assets/images/icons/double-click.svg?inline'),
  campaignBounced: require('@/assets/images/icons/404.svg?inline'),
  campaignComplained: require('@/assets/images/icons/smiley-bad.svg?inline'),
  campaignUnsubscribed: require('@/assets/images/icons/stop-sign.svg?inline'),
  campaignReport: require('@/assets/images/icons/performance-increase-2.svg?inline'),
  file: require('@/assets/images/icons/double-file.svg?inline'),
  promote: require('@/assets/images/icons/advertising-megaphone.svg?inline'),
  reply: require('@/assets/images/icons/reply.svg?inline'),
  approve: require('@/assets/images/icons/check-circle-1.svg?inline'),
  template: require('@/assets/images/icons/template.svg?inline'),
  brand: require('@/assets/images/icons/brand.svg?inline'),
  statusType: require('@/assets/images/icons/status-type.svg?inline'),
  location: require('@/assets/images/icons/location.svg?inline'),
  purchaseOrder: require('@/assets/images/icons/purchase-order.svg?inline'),
  supplier: require('@/assets/images/icons/supplier.svg?inline'),
  stockTake: require('@/assets/images/icons/stock-take.svg?inline'),
  unlinked: require('@/assets/images/icons/unlinked.svg?inline'),
  preset: require('@/assets/images/icons/preset.svg?inline'),
  policy: require('@/assets/images/icons/task-checklist-write.svg?inline'),
  columns: require('@/assets/images/icons/ui-columns.svg?inline'),
  dataBrowser: require('@/assets/images/icons/data-browser.svg?inline'),
  connect: require('@/assets/images/icons/hyperlink.svg?inline'),
  moveLeft: require('@/assets/images/icons/move-left.svg?inline'),
  moveRight: require('@/assets/images/icons/move-right.svg?inline'),
  download: require('@/assets/images/icons/download-bottom.svg?inline'),
  productCategory: require('@/assets/images/icons/hierarchy-6.svg?inline'),
  serviceCategory: require('@/assets/images/icons/hierarchy-6.svg?inline'),
  resourceGroup: require('@/assets/images/icons/hierarchy-6.svg?inline'),
  locked: require('@/assets/images/icons/lock-6.svg?inline'),
  news: require('@/assets/images/icons/newspaper-fold.svg?inline'),
  blog: require('@/assets/images/icons/conversation-chat-2.svg?inline'),
  feature: require('@/assets/images/icons/rating-star-ribbon.svg?inline'),
  update: require('@/assets/images/icons/information-circle.svg?inline'),
  list: require('@/assets/images/icons/layout-headline.svg?inline'),
  grid: require('@/assets/images/icons/layout-module-1.svg?inline'),
  library: require('@/assets/images/icons/saving-bank-international.svg?inline'),
  heartHands: require('@/assets/images/icons/love-heart-hold.svg?inline'),
  aiEdit: require('@/assets/images/icons/content-pencil-quill.svg?inline')
}

export default {
  name: 'OIcon',
  props: {
    icon: {
      type: [String, Array],
      required: true
    },
    size: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: null
    },
    width: {
      type: Number,
      default: null
    },
    fill: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    svg () {
      return icons[this.icon] || icons.placeholder
    },
    computedSize () {
      const { size, height, width } = this

      return {
        height: height || size,
        width: width || size
      }
    }
  },
  methods: {
    handleClick ($event) {
      this.$emit('click', $event)
    }
  }
}
</script>
