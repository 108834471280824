<template>
  <div>
    <transition name="fade">
      <div v-if="loading" class="fixed flex items-end justify-center inset-0 h-full w-full z-80000 p-4 bg-gray-100">
        <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div class="relative w-12 h-12 animate-spin rounded-full bg-gradient-to-r from-green-500 via-teal-500 to-blue-600 mx-auto mb-6" style="mask-image: radial-gradient(circle, rgba(0, 0, 0, 0) 50%, black 50%);" />

          <div v-if="message" class="text-center">
            <h4 class="text-blue-700 font-semibold uppercase tracking-wide mb-1">
              {{ message.title }}
            </h4>
            <p class="text-gray-600 text-base" v-html="message.info" />
          </div>
        </div>
        <div>
          <h4 class="text-gray-500 uppercase tracking-wide mb-6 text-sm text-center">
            <template v-for="(model, key) in syncing">
              <span v-if="model.state" :key="key">
                Syncing {{ model.label }}
              </span>
            </template>
          </h4>

          <img src="~/assets/images/logo/logo-navy-full.png" class="h-10 opacity-75 mx-auto mb-2">
          <p class="text-gray-500 text-xs text-center" @click="$auth.syncActive()">
            © 2023 Ovatu Pty Ltd ABN 92 159 394 484 ATF Ovatu Trading Trust ABN 74 504 757 106 | OVATU is a registered trademark of Ovatu Pty Ltd.
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      message: null,
      interval: null,
      messages: [
        { title: 'Did you know?', info: 'You can press CTRL + S from anywhere in the app to quickly access search' },
        { title: 'Did you know?', info: 'You can create custom permission roles in your account settings' },
        { title: 'Did you know?', info: 'It\'s impossible for most people to lick their elbows.' },
        { title: 'Did you know?', info: 'Ovatu is proud to be 100% carbon neutral' },
        { title: 'Did you know?', info: 'For every new customer Ovatu plants a tree' },
        { title: 'Did you know?', info: 'You can use our widgets to add book.app to your own website' },
        { title: 'Just a sec...', info: 'We are getting your data ready' },
        { title: 'Loading...', info: 'Reticulating splines...' },
        { title: 'Loading...', info: 'You are number 2843684714 in the queue' },
        { title: 'Loading...', info: 'Despite the constant negative press covfefe' },
        { title: 'Loading...', info: 'Does anyone actually read these?' },
        { title: 'Loading...', info: 'Some people are like clouds. When they go away, it\'s a brighter day.' },
        { title: 'Have a question?', info: 'Our support team are always ready to help!' },
        { title: 'Gettings things ready...', info: 'Thanks for using Ovatu! we appreciate it' }
      ]
    }
  },
  computed: {
    loading () {
      return !this.$auth.ready
    },
    ...mapGetters({
      customer: 'customer/isSyncing',
      service: 'service/isSyncing',
      employee: 'employee/isSyncing',
      segment: 'segment/isSyncing',
      product: 'product/isSyncing',
      integration: 'integration/isSyncing',
      form: 'form/isSyncing',
      customFields: 'customFields/isSyncing',
      discountModel: 'discountModel/isSyncing',
      chargeModel: 'chargeModel/isSyncing',
      paymentModel: 'paymentModel/isSyncing',
      tax: 'tax/isSyncing'
    }),
    syncing () {
      return {
        customer: { label: 'Customers', state: this.customer },
        service: { label: 'Services', state: this.service },
        employee: { label: 'Employees', state: this.employee },
        segment: { label: 'Segments', state: this.segment },
        product: { label: 'Products', state: this.product },
        integration: { label: 'Integrations', state: this.integration },
        form: { label: 'Forms', state: this.form },
        customFields: { label: 'Custom fields', state: this.customFields },
        discountModel: { label: 'Custom fields', state: this.discountModel },
        chargeModel: { label: 'Custom fields', state: this.chargeModel },
        paymentModel: { label: 'Custom fields', state: this.paymentModel },
        tax: { label: 'Custom fields', state: this.tax }
      }
    }
  },
  watch: {
    loading: {
      handler () {
        if (this.loading) {
          this.setInterval()
        } else {
          clearInterval(this.interval)
        }
      },
      immediate: true
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    setInterval () {
      if (!this.interval) {
        const interval = setInterval(() => {
          this.setMessage()

          if (!this.loading) {
            clearInterval(this.interval)
          }
        }, 8000)

        this.interval = interval
      }
    },
    setMessage () {
      const messages = this.messages
      const message = messages[Math.floor(Math.random() * messages.length)]

      this.message = message
    }
  }
}
</script>
