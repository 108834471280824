<template>
  <div v-if="isVisible" class="w-full border-b flex items-center p-3 pl-0 justify-between">
    <div class="flex items-center">
      <o-icon icon="x" class="p-3" :size="10" @click="handleHideBanner" />

      <img src="~/assets/images/logo/app-icon.png" class="h-12">

      <div class="ml-4">
        <p class="text-sm text-gray-800 leading-none font-medium mb-1">
          Ovatu Next
        </p>
        <p class="text-xs text-gray-500 leading-none">
          Install the Ovatu app on your device
        </p>
      </div>
    </div>

    <div>
      <a v-if="isApple" href="https://itunes.apple.com/au/app/ovatu-next/id1589890786" target="_blank" class="px-3 py-2 bg-blue-600 text-white font-medium uppercase text-sm block rounded-full leading-none tracking-wider">
        Install
      </a>
      <a v-if="isAndroid" href="https://play.google.com/store/apps/details?id=com.ovatu.next" target="_blank" class="px-3 py-2 bg-blue-600 text-white font-medium uppercase text-sm block rounded-full leading-none tracking-wider">
        Install
      </a>
    </div>
  </div>
</template>

<script>
import UAParser from 'ua-parser-js'
import Cookies from 'js-cookie'

export default {
  data () {
    return {
      hidden: true,
      device: null,
      ua: null
    }
  },
  computed: {
    isVisible () {
      return !this.hidden && (this.isAndroid || (this.isApple && !this.isSafari))
    },
    isAndroid () {
      return this.device?.android?.phone || this.device?.android?.tablet
    },
    isApple () {
      return this.device?.apple?.phone || this.device?.apple.tablet
    },
    isSafari () {
      return this.ua?.browser?.name === 'Safari' || this.ua?.browser?.name === 'Mobile Safari'
    }
  },
  mounted () {
    this.hidden = Cookies.get('appbanner_hidden') || false
    this.device = this.$mobile.isMobile()

    this.ua = UAParser()
  },
  methods: {
    handleHideBanner () {
      Cookies.set('appbanner_hidden', true, { expires: 60 })
      this.hidden = true
    }
  }
}
</script>
