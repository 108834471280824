export const pages = [
  // { label: 'Devices', icon: 'devices', info: 'Manage devices authorised to access your account', path: '/account/devices' },
  { label: 'Users & Roles', icon: 'employee', info: 'Manage admins users, employees and role permissions', path: '/account/users/users', match: '/account/users' },
  { label: 'Plan & Billing', icon: 'creditCard', info: 'Manage your plan and subscription', path: '/account/billing' },
  { label: 'Referral', icon: 'info', info: 'Invite your friends to Ovatu and receive a gift', path: '/account/referral' },
  { label: 'Security', icon: 'services', info: 'Pin Settings and IP Blacklists', path: '/account/security' },
  { label: 'Cached data', icon: 'sync', info: 'View status of locally stored account data', path: '/account/storage' },
  { label: 'Privacy', icon: 'product', info: 'Privacy, marketing and EU cookie consent', path: '/account/privacy' },
  // { label: 'Roles', icon: 'permission', info: 'Create and edit groups of permissions (roles) that can be assigned to Employees', path: '/account/roles' },
  { label: 'Integrations', icon: 'menuIntegrations', info: 'Connect your Ovatu account with other apps', path: '/account/integrations' },
  { label: 'Sessions', icon: 'devices', info: 'Account access activity', path: '/account/sessions' },
  { label: 'Calendar feeds', icon: 'calendar', info: 'Add Ovatu appoints to your calendar', path: '/account/calendar' },
  { label: 'Policy', icon: 'policy', info: 'Ovatu terms & conditions', path: '/account/policy' },
  { label: 'Exports', icon: 'export', info: 'Download export file requests', path: '/account/exports' },
  { label: 'Notifications', icon: 'menuNotifications', info: 'Manage account notifications', path: '/account/notifications' }
]
