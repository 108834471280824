<template>
  <div class="relative bg-white h-screen w-screen overflow-hidden">
    <!--<img src="@/assets/images/illustrations/illustration-forest@1x.png" alt="" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-none">-->

    <div class="absolute inset-0 h-full w-full bg-secondary-100/20 opacity-20">
      <img src="@/assets/images/illustrations/illustration-ocean@1x.webp" class="absolute inset-0 h-full w-full object-cover scale-102 opacity-[0.05]" />
    </div>

    <router-view v-if="!slug || location" :location="location" />

    <div v-if="error" class="h-screen w-screen fixed inset-0 bg-gray-100 flex items-center z-80000">
      <div class="mx-auto max-w-md px-5 text-gray-700 text-center">
        <div class="text-6xl font-dark font-bold leading-none mb-6">
          {{ 'Something went wrong' }}
        </div>
        <p class="text-2xl font-light leading-normal">
          {{ 'Sorry, it looks like theres been error or the requested account doesn\'t exist...' }}
        </p>
        <p class="mb-8 mt-8">
          Contact hello@ovatu.com if you need some help
        </p>

        <o-button to="/auth/domain" size="lg" variant="info">
          Back to safety
        </o-button>
      </div>
    </div>

    <loading />

    <portal-target name="modal-container" multiple />
    <portal-target name="dialog-container" multiple />
  </div>
</template>

<script>
import * as API from '~/api'

import Loading from '@/components/layout/Loading'

export default {
  components: {
    Loading
  },
  provide () {
    return {
      location: this.location
    }
  },
  data () {
    return {
      error: false,
      location: null
    }
  },
  head () {
    return {
      title: 'Ovatu Manager'
    }
  },
  computed: {
    slug () {
      return this.$auth.location
    }
  },
  mounted () {
    this.fetchDomain()

    const redirect = this.$route.query.redirect

    if (redirect) {
      this.$store.commit('auth/set', { key: 'redirect', value: redirect })
    }
  },
  methods: {
    async fetchDomain () {
      try {
        const slug = this.slug

        if (slug) {
          const response = await API.Auth.domain(slug)

          if (response) {
            this.location = response

            document.title = this.location.title + ' - Ovatu Manager'
          }
        }
      } catch (error) {
        this.error = true
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss">
  $gradient-2: rgb(0, 154, 255);
  $gradient-1: rgb(24, 198, 82);
  $gradient-3: rgb(68, 93, 140);
  $gradient-4: yellow;

  .gradient-background {
    background: {
      image:
        radial-gradient(rgba($gradient-1, 0.5), rgba($gradient-1, 0) 40vw),
        radial-gradient(rgba($gradient-2, 0.3), rgba($gradient-2, 0) 40vw),
        radial-gradient(rgba($gradient-3, 0.5), rgba($gradient-3, 0) 40vw);
      position:
        -40vw 14rem,
        50% 10rem,
        60vw 14rem,
        -10vw calc(14rem + 20vw),
        30vw calc(14rem + 20vw);
      size: 80vw 80vw;
      repeat: no-repeat;
    }
  }
</style>
