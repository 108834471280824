import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'
import cookie from 'cookie'

export default ({ store, req }) => {
  createPersistedState({
    key: 'auth',
    paths: [
      'menu',
      'sidebar',
      'alerts.filter',
      'dashboard.showPastReservations',
      'auth.active',
      'auth.guids',
      'schedule.sectionSize',
      'schedule.showResources',,
      'order.print',
    ],
    storage: {
      getItem: (key) => {
        if (process.server && req.headers.cookie) {
          const parsedCookies = cookie.parse(req.headers.cookie)
          return parsedCookies[key]
        } else {
          return Cookies.get(key)
        }
      },
      setItem: (key, value) => {
        Cookies.set(key, value, { expires: 365, secure: false })
      },
      removeItem: (key) => {
        Cookies.remove(key)
      }
    }
  })(store)
}
