import Model, { Request } from '../model'
import APIObject from '../object'
import { DateYMD } from '../dates'

import Customer from './customer'
import CustomerPass from './customerPass'
import CustomField from './customField'
import CustomerForm from './customerForm'
import Employee from './employee'
import Integration, { IntegrationLabel, IntegrationAction, IntegrationActionType } from './integration'
import Reservation, { ReservationStatus, ReservationCustomerRequestType, ReservationEmailType } from './reservation'
import ReservationStatusType from './reservationStatusType'
import ServiceResource,  { ServiceResourceDefaultSelection, ServiceResourceAssignment } from './serviceResource'
import Voucher from './voucher'
import Recurrence from './recurrence'
import Upload from './upload'

export default class ReservationCustomer extends Model {
  static modelName () {
    return 'reservationCustomer'
  }

  objectID () {
    return this.idReservationCustomer
  }

  relations () {
    return {
      availableCustomerPasses: { type: CustomerPass },
      availableVouchers: { type: Voucher },
      customer: { type: Customer },
      customerPass: { type: CustomerPass },
      customerRequestType: { type: ReservationCustomerRequestType },
      customFields: { type: CustomField },
      date: { type: DateYMD },
      forms: { type: CustomerForm },
      options: { type: ReservationCustomerOptions },
      recurrence: { type: Recurrence },
      reservation: { type: Reservation },
      resources: { type: ReservationResource },
      status: { type: ReservationStatus },
      statusType: { type: ReservationStatusType },
      validPasses: { type: CustomerPass },
      voucher: { type: Voucher },
      integrations: { type: ReservationCustomerIntegration }
    }
  }

  cancel ({ sendEmail, future, cancellationType, cancellationNote } = {}) {
    const sendEmailParam = sendEmail ? 1 : 0
    const futureParam = future ? 1 : 0
    const cancellationTypeParam = cancellationType ? cancellationType.idReservationCancellationType : null
    const cancellationNoteParam = cancellationNote
    const url = this.constructor.modelBaseURL() + '/cancel?id=' + this.objectID() + '&date=' + this.date + '&sendEmail=' + sendEmailParam + '&future=' + futureParam + '&type=' + cancellationTypeParam + '&note=' + cancellationNoteParam
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  unCancel () {
    const url = this.constructor.modelBaseURL() + '/cancel?id=' + this.objectID() + '&sendEmail=0&future=0&undo=1'
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  checkIn (findPass, customerPass) {
    const findPassParam = findPass ? 1 : 0
    const customerPassParam = customerPass ? customerPass.idCustomerPass : ''
    const url = this.constructor.modelBaseURL() + '/checkIn?id=' + this.objectID() + '&findPass=' + findPassParam + '&customerPass=' + customerPassParam
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  unCheckIn () {
    const url = this.constructor.modelBaseURL() + '/checkIn?id=' + this.objectID() + '&findPass=0&customerPass=&undo=1'
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  didNotShow () {
    const url = this.constructor.modelBaseURL() + '/noShow?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  unDidNotShow () {
    const url = this.constructor.modelBaseURL() + '/noShow?id=' + this.objectID() + '&undo=1'
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  unDelete () {
    const url = this.constructor.modelBaseURL() + '/delete?id=' + this.objectID() + '&undo=1'
    return this.constructor.requestSuccess(Request.get(url))
  }

  confirm (sendMail, undo = 0) {
    const sendMailParam = sendMail ? 1 : 0
    const url = this.constructor.modelBaseURL() + '/confirm?id=' + this.objectID() + '&sendEmail=' + sendMailParam + '&undo=' + undo
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  email (type = ReservationEmailType.received) {
    const url = this.constructor.modelBaseURL() + '/email?id=' + this.objectID() + '&date=' + this.date + '&type=' + type.value
    return this.constructor.requestSuccess(Request.get(url))
  }

  uploads ({ page = 1 } = {}) {
    const url = this.constructor.modelBaseURL() + '/uploads?id=' + this.objectID() + '&page=' + page
    return this.constructor.requestList(Request.get(url), Upload)
  }

  applyVoucher (voucher) {
    const url = this.constructor.modelBaseURL() + '/applyVoucher?id=' + this.objectID() + '&voucher=' + voucher.idVoucher
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  removeVoucher () {
    const url = this.constructor.modelBaseURL() + '/removeVoucher?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  assignCustomerPass (customerPass) {
    const url = this.constructor.modelBaseURL() + '/assignCustomerPass?id=' + this.objectID() + '&pass=' + customerPass.idCustomerPass
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  removeCustomerPass () {
    const url = this.constructor.modelBaseURL() + '/removeCustomerPass?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  assignCustomerPasses (customerPasses) {
    const url = this.constructor.modelBaseURL() + '/assignCustomerPass?id=' + this.objectID()

    const data = {
      customerPasses
    }

    return this.constructor.requestItem(Request.post(url, JSON.stringify(data)), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  applyVouchers (vouchers) {
    const url = this.constructor.modelBaseURL() + '/applyVoucher?id=' + this.objectID()

    const data = {
      vouchers
    }

    return this.constructor.requestItem(Request.post(url, JSON.stringify(data)), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  integrationAction (integration, action) {
    const url = this.constructor.modelBaseURL() + '/integrationAction?id=' + this.objectID() + '&guid=' + integration.guid + '&action=' + action.code
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  setResources (existingResources) {
    const service = this.service
    const resourcesForAssignment = service?.resources ? service.resourcesForAssignment(ServiceResourceAssignment.customer) : []

    const items = []
    const usedResources = []

    for (const serviceResource of resourcesForAssignment) {
      const existing = existingResources
        ?.filter(resource => resource.serviceResource.idServiceResource === serviceResource.idServiceResource)
        .map(resource => resource.resource)

      for (let i = 0; i < serviceResource.quantity; i++) {
        const reservationResource = new ReservationResource({ serviceResource })
        reservationResource.count = i

        if (existingResources !== undefined) {
          const available = existing
            .filter(r => !usedResources.includes(r.idEmployee))

          reservationResource.resource = available[0] || ServiceResourceDefaultSelection.none.value
          usedResources.push(reservationResource.resource.idEmployee)
        } else if (serviceResource.defaultSelection === ServiceResourceDefaultSelection.firstAvailable) {
          reservationResource.resource = ServiceResourceDefaultSelection.firstAvailable.value
        } else {
          reservationResource.resource = ServiceResourceDefaultSelection.none.value
        }

        items.push(reservationResource)
      }
    }

    this.resources = items
  }

  get _servicesLabel () {
    return this.servicesLabel || this.services
      .map(({ service }) => service.title)
      .join(', ')
  }

  get employeesLabel () {
    const employees = this.services
      .map(({ employee }) => {
        return employee.displayName
      })

    return Array.from(new Set(employees))
      .join(', ')
  }

  get durationLabel () {
    return this.services
      .map(({ service }) => service.duration)
      .reduce((a, b) => a + b, 0)
  }

  get startTime () {
    return this.reservation.startTime
  }

  get duration () {
    return this.reservation.duration
  }

  get services () {
    return [this.reservation]
  }

  get isStatusConfirmed () {
    return this.status === ReservationStatus.confirmed
  }

  get isStatusUnconfirmed () {
    return this.status === ReservationStatus.unconfirmed
  }

  get isStatusCancelled () {
    return this.status === ReservationStatus.cancelled
  }

  get isRecurring () {
    return !!this.recurrenceGuid
  }

  get hasVoucher () {
    return !!this.voucher && Object.keys(this.voucher).length
  }

  get hasPass () {
    return !!this.customerPass
  }
}

export class ReservationResource extends APIObject {
  relations () {
    return {
      resource: { type: Employee },
      serviceResources: { type: ServiceResource }
    }
  }
}

export class ReservationCustomerIntegration extends APIObject {
  relations () {
    return {
      integration: { type: Integration },
      labels: { type: IntegrationLabel },
      actions: { type: IntegrationAction }
    }
  }
}

export class ReservationCustomerOptions extends APIObject {
  relations () {
    return {
      statusTypes: { type: ReservationStatusType },
      availableCustomerPasses: { type: CustomerPass },
      availableVouchers: { type: Voucher }
    }
  }
}
