<template>
  <div>
    <div>
      <o-dropdown
        ref="dropdown"
        class="w-full"
        :arrow="false"
        placement="bottom-start"
        detect-overflow
        same-width
        :disabled="disabled"
        :trigger="[]"
        :clickaway="false"
      >
        <o-field-group label="Address line 1" class="mb-4 w-full">
          <text-field
            v-model="_value.address"
            leading="address"
            autocomplete="new-password"
            :loading="loading"
            @input="fetchAddresses"
            @blur="handleInputBlur"
            @focus="handleInputFocus"
            @keyup.up="handleInputKeyUp(-1)"
            @keyup.down="handleInputKeyUp(1)"
            @keyup.enter="handleInputEnter"
          />
        </o-field-group>

        <template #content="{ overflow }">
          <div
            class="text-sm flex flex-col"
            :style="{
              maxHeight: overflow && overflow.bottom > 0 ? `${400 - overflow.bottom}px` : '400px'
            }"
          >
            <o-scroll v-if="result.addresses && result.addresses.length">
              <ul
                v-for="(address, index) in result.addresses"
                :key="index"
              >
                <li
                  :class="[
                    'cursor-pointer select-none relative py-2 px-3  transition-colors border-b last:border-none',
                    focused === index ? 'bg-blue-100 text-blue-700' : 'text-gray-900'
                  ]"
                  role="option"
                  @click="setAddress(address)"
                  @mouseenter="focused = index"
                >
                  {{ address.formattedAddress }}
                </li>
              </ul>
            </o-scroll>

            <p v-else class="text-center px-4 py-2 text-gray-600">
              No matching address found...
            </p>
          </div>
        </template>
      </o-dropdown>

      <div class="flex space-x-4 mb-4">
        <o-field-group class="w-full" label="Address Line 2">
          <text-field v-model="_value.address2" />
        </o-field-group>
      </div>

      <div class="flex space-x-4 mb-4">
        <o-field-group class="w-full md:w-1/2" label="Suburb">
          <text-field v-model="_value.suburb" />
        </o-field-group>
        <o-field-group class="w-full md:w-1/2" label="City">
          <text-field v-model="_value.city" />
        </o-field-group>
      </div>
      <div class="flex space-x-4 mb-4">
        <o-field-group class="w-full md:w-1/2" label="Region">
          <text-field v-model="_value.region" />
        </o-field-group>
        <o-field-group class="w-full md:w-1/2" label="Postcode">
          <text-field v-model="_value.postcode" />
        </o-field-group>
      </div>
      <div class="flex space-x-4 mb-4">
        <o-field-group class="w-full md:w-1/2" label="Country">
          <text-field v-model="_value.country" />
        </o-field-group>
      </div>
    </div>

    <div id="address-autocomplete-tag" />
  </div>
</template>

<script>
import Radar from 'radar-sdk-js';
import debounce from 'lodash/debounce'

import {
  TextField
} from '@/components/fields'

const radar_test_key = 'prj_test_pk_7d0b8d3107d27df9fb890c5141faeb27f218a146'
const radar_live_key = 'prj_test_pk_7d0b8d3107d27df9fb890c5141faeb27f218a146'

export default {
  name: 'OAddress',
  components: {
    TextField
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      result: null,
      sessionToken: null,
      focused: -1
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    isDev () {
      return this.$config.instance === 'dev'
    }
  },
  mounted () {
    Radar.initialize(this.isDev ? radar_test_key : radar_live_key);
  },
  methods: {
    fetchAddresses: debounce(async function () {
      this.loading = true

      try {
        const result = await Radar.autocomplete({
          query: this._value.address,
          limit: 5
        })

        this.result = result
        this.loading = false

        this.$refs.dropdown.open()
      } catch (e) {
        console.log(e)
      }
    }, 500),
    setAddress (result) {
      console.log(result)

      this._value.address = result.addressLabel
      this._value.address2 = ''
      this._value.suburb = result.borough || ''
      this._value.city = result.city
      this._value.region = result.state
      this._value.postcode = result.postalCode
      this._value.country = result.country
    },
    handleInputBlur () {
      setTimeout(() => {
        this.$refs.dropdown.close()
      }, 200)
    },
    handleInputFocus () {
      if (this.result?.addresses) {
        this.$refs.dropdown.open()
      }
    },
    handleInputKeyUp (val) {
      const addresses = this.result?.addresses

      if (addresses) {
        const focused = this.focused
        const next = focused + val

        if (next > -1 && next < addresses.length) {
          this.focused = next
        }
      }
    },
    handleInputEnter () {
      const addresses = this.result?.addresses

      if (addresses) {
        const focused = this.focused
        const address = addresses[focused]
        this.setAddress(address)
        this.$refs.dropdown.close()
      }
    }
  }
}
</script>
