<template>
  <li v-if="visible" :class="[isFeatureEnabled ? 'order-1' : 'order-2']">
    <nuxt-link
      v-if="visible"
      :to="link"
      :class="[
        'w-full px-5 py-4 transition-all cursor-pointer leading-none text-sm block border-b',
        active ? 'bg-primary-50 text-primary-700 active:text-primary-700' : 'text-gray-700',
        isFeatureEnabled ? 'cursor-pointer hover:bg-primary-100' : 'cursor-default opacity-50'
      ]"
    >
      <div class="flex items-start space-x-3">
        <o-icon v-if="item.icon" :icon="item.icon" :size="18" :class="{ 'text-gray-600' : !active }" />
        <span class="mt-1">
          <span class="font-medium">
            {{ item.label }}
          </span>
          <span v-if="item.info" class="block text-sm text-gray-600 font-normal pt-3px leading-tight">
            {{ item.info }}
          </span>
        </span>
      </div>
    </nuxt-link>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      link: '/'
    }
  },
  computed: {
    active () {
      const path = this.$route.path
      return path.includes(this.item.match) || path.includes(this.link)
    },
    isUserOwner () {
      const employee = this.$auth.info.employee
      return employee.role.isRoleTypeOwner
    },
    visible () {
      return this.isGateEnabled && ((this.isGrantAllowed && this.isFeatureEnabled) || this.isUserOwner)
    },
    isGrantAllowed () {
      const item = this.item

      // Check require permissions of tabs
      if (item.tabs) {
        const tabs = item.tabs
        const allowed = Object.values(tabs).some((tab) => {
          const grants = tab.path ? this.$grants.route(tab.path) : null

          if (!grants) {
            this.setLink(tab.path)
            return true
          }

          const allowed = this.$grants.all(grants)

          if (allowed) {
            this.setLink(tab.path)
          }

          return allowed
        })

        return allowed
      }
      // Check required permissions of page
      const grants = item.path ? this.$grants.route(item.path) : null

      if (!grants) {
        this.setLink(item.path)
        return true
      }

      const allowed = this.$grants.all(grants)

      if (allowed) {
        this.setLink(item.path)
      }

      return allowed
    },
    isFeatureEnabled () {
      const item = this.item

      if (item.tabs) {
        const tabs = item.tabs
        const enabled = Object.values(tabs).some((tab) => {
          const features = tab.path ? this.$features.route(tab.path) : null

          if (!features) {
            return true
          }

          const allowed = this.$features.all(features)

          return allowed
        })

        return enabled
      }

      // Check required permissions of page
      const features = item.path ? this.$features.route(item.path) : null

      if (!features) {
        return true
      }

      const allowed = this.$features.all(features)

      return allowed
    },
    isGateEnabled () {
      const item = this.item

      if (item.tabs) {
        const tabs = item.tabs
        const enabled = Object.values(tabs).some((tab) => {
          const gates = tab.path ? this.$gates.route(tab.path) : null

          if (!gates) {
            return true
          }

          const allowed = this.$gates.all(gates)

          return allowed
        })

        return enabled
      }

      // Check required permissions of page
      const gates = item.path ? this.$gates.route(item.path) : null

      if (!gates) {
        return true
      }

      const allowed = this.$gates.all(gates)

      return allowed
    }
  },
  methods: {
    setLink (link) {
      this.link = link
    }
  }
}
</script>
