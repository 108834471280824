<template>
  <div class="relative">
    <textarea
      :id="id"
      ref="textarea"
      v-model="currentValue"
      :autofocus="autofocus"
      :disabled="disabled"
      :name="name"
      :placeholder="placeholder"
      :readonly="readonly"
      :required="required"
      :maxlength="maxlength"
      :rows="rows"
      :wrap="wrap"
      :class="[
        currentClass,
        counter && maxlength ? 'rounded-b-none border-b-0' : ''
      ]"
      @blur="onBlur"
      @focus="onFocus"
      @keyup="$emit('keyup', $event)"
      @keydown="$emit('keydown', $event)"
    />

    <div v-if="counter && maxlength" class="flex justify-end text-xs font-medium bg-gray-100 rounded-b-md py-1 px-2 border">
      {{ count }} / {{ maxlength }}
    </div>
  </div>
</template>

<script>

import classes from '@/mixins/fields/classes.js'
import attributes from '@/mixins/fields/attributes.js'
import methods from '@/mixins/fields/methods.js'

export default {
  mixins: [attributes, classes, methods],
  props: {
    value: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    maxlength: {
      type: [String, Number],
      default: null
    },
    readonly: {
      type: Boolean,
      default: undefined
    },
    rows: {
      type: [String, Number],
      default: 4
    },
    wrap: {
      type: String,
      default: 'soft'
    },
    counter: {
      type: Boolean,
      default: false
    },
    baseClass: {
      type: [String, Object, Array],
      default: 'w-full block bg-white border border-gray-300 rounded-md focus-within:ring-2 rounded-md text-sm text-gray-800'
    },
    defaultStatusClass: {
      type: [String, Object, Array],
      default: 'bg-white'
    },
    warningStatusClass: {
      type: [String, Object, Array],
      default: 'border-yellow-400 bg-yellow-100'
    },
    errorStatusClass: {
      type: [String, Object, Array],
      default: 'border-red-300 bg-red-100'
    },
    successStatusClass: {
      type: [String, Object, Array],
      default: 'border-green-300 bg-green-100'
    },
    disabledClass: {
      type: [String, Object, Array],
      default: 'bg-gray-100 cursor-not-allowed opacity-75'
    },
    defaultSizeClass: {
      type: [String, Object, Array],
      default: 'p-2'
    },
    largeSizeClass: {
      type: [String, Object, Array],
      default: 'p-4 text-lg'
    },
    smallSizeClass: {
      type: [String, Object, Array],
      default: 'p-2 text-sm'
    }
  },
  data () {
    return {
      currentValue: this.value,
      valueWhenFocus: null
    }
  },
  computed: {
    count () {
      return this.currentValue?.length || 0
    }
  },
  watch: {
    value (value) {
      this.currentValue = value
    },
    currentValue (currentValue) {
      this.$emit('input', currentValue)
    }
  },
  methods: {
    onBlur (e) {
      this.$emit('blur', e)
      if (this.currentValue !== this.valueWhenFocus) {
        this.$emit('change', this.currentValue)
      }
    },
    onFocus (e) {
      this.$emit('focus', e)
      this.valueWhenFocus = this.currentValue
    }
  }
}
</script>
