<template>
  <div class="flex items-center">
    <template v-for="(filter, key) in filters.all">
      <o-chip
        v-if="!filter.isEmpty() && filter.valueLabel"
        :key="key"
        class="text-sm flex align-items  mr-2 flex-shrink-0"
        icon="x"
        icon-after
        variant="info"
        flat
        square
        size="sm"
        @action="filter.clear()"
      >
        <div class="flex items-center justify-between flex-wrap">
          <div class="py-2px flex-shrink-0">
            <p>
              <span class="font-semibold pb-2px">{{ filter.label }}</span>
            </p>
          </div>
          <div>
            <o-loader v-if="filter.isLoading" :scale="0.4" class="flex-shrink-0 h-3 w-3 ml-3" />
            <!--<template v-else>
              ({{ (filter.items) ? filter.items.length : 0 }})
            </template>-->
          </div>
        </div>
      </o-chip>
    </template>

    <o-dropdown padding="">
      <o-button flat outline size="sm" icon="filter">
        Filters
      </o-button>

      <template #content>
        <div class="w-full sm:w-80 h-100">
          <o-scroll class="w-full h-100">
            <div class="flex items-center justify-between p-3 border-b border-gray-300">
              <p class="text-gray-800 font-medium">
                Filters
              </p>

              <a class="text-sm text-blue-500 cursor-pointer" @click="filters.clear()">
                Clear filters
              </a>
            </div>
            <template v-for="(filter, key) in _filters">
              <o-collapse
                :key="key"
                :clickable="false"
                header-class="flex flex-row items-center h-10 justify-between px-3 text-gray-700 text-sm font-medium hover:text-gray-800"
                body-class="border-t border-gray-300"
                class="border-b border-gray-300 last:border-none"
              >
                <template #header>
                  <div class="flex justify-between w-full items-center">
                    {{ filter.label }}
                    <o-chip
                      v-if="!filter.isEmpty() && filter.isLoading"
                      size="sm"
                      variant="info"
                      flat
                      square
                      class="mr-3"
                    >
                      <o-loader :scale="0.4" class="h-3 w-3" />
                    </o-chip>
                  </div>
                </template>

                <template #toggle="{ toggle }">
                  <o-button
                    size="sm"
                    transparent
                    icon="accordion"
                    class="-mr-1"
                    @click="toggle"
                  />
                </template>

                <div class="w-full">
                  <component
                    :is="filter.component"
                    v-model="filter.value"
                    v-bind="filter.props"
                    :disabled="filter.disabled"
                    :options="filter.options"
                    :custom="filter.custom"
                    :name="filter.label"
                    :status="filter.validation ? filter.validation.status : null"
                  />
                </div>
              </o-collapse>
            </template>
          </o-scroll>
        </div>
      </template>
    </o-dropdown>
  </div>
</template>

<script>
import FieldsWithDateRange from '@/components/customer/list/FieldsWithDateRange'
import DateOfBirth from '@/components/customer/list/DateOfBirth'

import {
  OCustomField
} from '@/components/elements'

import {
  CheckboxField,
  CheckboxGroupField,
  DateRangeOptionalField,
  RadioGroupField,
  SegmentField,
  SelectField,
  TextField,
  ToggleField
} from '@/components/fields'

import DateRangeFilter from '@/components/filters/elements/DateRange'

export default {
  name: 'OFilters',
  components: {
    FieldsWithDateRange,
    DateOfBirth,
    OCustomField,
    CheckboxField,
    CheckboxGroupField,
    DateRangeOptionalField,
    RadioGroupField,
    SegmentField,
    SelectField,
    TextField,
    ToggleField,
    DateRangeFilter
  },
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  computed: {
    _filters () {
      if (this.filters) {
        if (this.filters.filters) {
          return this.filters.filters
        }

        if (this.filters.categorised) {
          return this.filters.categorised
            .reduce((acc, category) => {
              acc.push(...category.filters)
              return acc
            }, [])
        }
      }

      return []
    }
  }
}
</script>
