import Fuse from 'fuse.js'

import Model, { Request } from '../model'
import APIObject from '../object'

import { LocalFilterController, Filter, RemoteFilter, FilterCategory, FilterOptions } from '../filter'

import Customer from './customer'
import Order from './order'
import Pass from './pass'
import Reservation from './reservation'

export default class CustomerPass extends Model {
  static modelName () {
    return 'customerPass'
  }

  objectID () {
    return this.idCustomerPass
  }

  relations () {
    return {
      customer: { type: Customer },
      options: { type: CustomerPassOptions },
      order: { type: Order },
      pass: { type: Pass },
      allReservations: { type: Reservation }
    }
  }

  static filter (filters) {
    const url = this.modelBaseURL() + '/filter'
    const request = {
      filters
    }

    return this.requestItem(Request.post(url, JSON.stringify(request)), CustomerPassFilterResponse)
  }

  static ids (ids, page = 1) {
    const url = this.modelBaseURL() + '/ids?page=' + page

    const data = {
      ids
    }

    return this.requestList(Request.post(url, JSON.stringify(data)), this)
  }

  static listIDs (ids) {
    // use a default runner (to call the list method)
    const that = this

    const runner = (page) => {
      return that.ids(ids, page)
    }

    return this.listRunner(runner)
  }

  email ({ fromName, toName, toEmail, body } = {}) {
    const url = this.constructor.modelBaseURL() + '/email?id=' + this.objectID()
    const data = {
      fromName,
      toName,
      toEmail,
      body
    }

    return this.constructor.requestSuccess(Request.post(url, JSON.stringify(data)))
  }

  /* transactions () {
    const url = this.constructor.modelBaseURL() + '/transactions?id=' + this.objectID() // + '&page=' + page
    return this.constructor.requestList(Request.get(url), CustomerPassTransaction)
  } */

  get isUnlimitedUse () {
    return this.usesRemaining === -1
  }

  get isLimitedUse () {
    return this.usesRemaining !== -1
  }

  get expires () {
    return !!this.expiryDate
  }

  get neverExpires () {
    return !this.expiryDate
  }

  get customerLabel () {
    if (this.isGift) {
      return `${this.gift?.to?.name} (${this.gift?.to?.email})`
    }

    return this.customer?.displayName || '-'
  }

  get isGift () {
    return !!this.gift
  }

  get isGiftDelivered () {
    return this.isGift && this.gift.delivered
  }
}

export class CustomerPassOptions extends APIObject {
  relations () {
    return {
      passes: { type: Pass }
    }
  }
}

export class CustomerPassTransaction extends APIObject {
  relations () {
    return {
      order: { type: Order }
    }
  }
}

export class CustomerPassFilterResponse extends APIObject {

}

export class CustomerPassFilterController extends LocalFilterController {
  constructor ({ $store }) {
    super(CustomerPass)

    /* this.search = new Filter({
      label: 'Search',
      matches (customerPasses) {
        const fuse = new Fuse(customerPasses, {
          keys: [
            'customer.firstName',
            'customer.lastName',
            'customer.company',
            'priceLabel',
            'infoLabel',
            'pass.name',
            'pass.infoLabel',
            'idCustomerPass'
          ]
        })

        const results = fuse.search(this.value)
        return results.map(({ item }) => item)
      }
    }) */

    this.query = new RemoteFilter('query', {
      label: 'Search',
      info: 'Search customer passes',
      component: 'TextField',
      getParams () {
        return {
          query: this.value
        }
      }
    })

    /* this.latestPerCustomer = new Filter({
      label: 'Only latest',
      info: 'Include only the latest pass for each customer',
      component: 'ButtonField',
      matches (customerPasses) {
        const customers = []

        return customerPasses
          .sort((a, b) => {
            return a.creates < b.created
          })
          .filter((customerPass) => {
            const customer = customerPass.customer.idCustomer
            if (customers.includes(customer.idCustomer)) {
              return false
            }

            customers.push(customer)
            return true
          })
      }
    }) */

    this.latestPerCustomer = new RemoteFilter('latestPerCustomer', {
      label: 'Only latest',
      info: 'Include only the latest pass for each customer',
      component: 'ToggleField',
      getParams () {
        return {
          value: true
        }
      }
    })

    this.latestPerCustomer = new RemoteFilter('latestPerCustomer', {
      label: 'Latest per customer',
      info: 'Include only the latest pass for each customer',
      component: 'ToggleField',
      getParams () {
        return {
          time: Date.now()
        }
      }
    })

    this.pass = new RemoteFilter('pass', {
      label: 'Passes',
      info: 'Customer passes using the selected passes',
      component: 'CheckboxGroupField',
      props: {
        search: true,
        class: 'border rounded overflow-hidden bg-gray-200 h-80 px-1'
      },
      value: [],
      options: [],
      valueLabel () {
        const value = this.value

        if (value.length) {
          const labels = value.slice(0, 3).map(({ name }) => name).join(', ')
          return labels + (value.length > 3 ? ` and ${value.length - 3} more` : '')
        }

        return 'All passes'
      },
      getParams () {
        return {
          passes: this.value.map(({ idPass }) => idPass)
        }
      },
      clear () {
        this.value = []
      }
    })

    this.expired = new RemoteFilter('expired', {
      label: 'Expired',
      info: 'Include customer passes that are active/expired',
      component: 'SegmentField',
      value: null,
      options: [
        { value: false, text: 'Active' },
        { value: true, text: 'Expired' }
      ],
      props: {
        class: 'h-12',
      },
      getParams () {
        return {
          expired: this.value,
          time: null
        }
      },
      isEmpty () {
        return this.value === null
      }
    })

    this.deleted = new RemoteFilter('deleted', {
      label: 'Deleted',
      info: 'Include customer passes that have been deleted',
      component: 'ToggleField',
      props: {
        class: 'p-3',
      },
      getParams () {
        return { deleted: true }
      }
    })

    this.passOptions = new FilterOptions({
      async map () {
        const passes = await Pass.list()
        return passes.items
          .map(pass => ({
            value: pass,
            text: pass.name,
            info: pass.infoLabel
          }))
      },
      set: (options) => {
        this.pass.options = options
      }
    })

    this.filters = [
      this.expired,
      this.deleted,
      this.latestPerCustomer,
      this.pass
    ]
  }
}
