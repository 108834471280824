export const sections = {
  schedule: {
    label: 'Schedule',
    icon: 'schedule',
    info: 'Manage default schedule/calendar settings',
    path: '/manage/schedule',
    tags: ['calendar', 'bookings', 'timeline', 'slot size', 'start', 'week', 'time', 'threshold']
  },
  location: {
    label: 'Location',
    icon: 'location',
    info: 'Manage location settings for your business',
    path: '/manage/location',
    tags: ['location', 'settings', 'business', 'timezone', 'address', 'contact', 'sales', 'receipts', 'locale', 'logo', 'currency', 'tax']
  },
  employees: {
    label: 'Employees',
    icon: 'attendees',
    info: 'Manage employee details, services, roster and overrides',
    path: '/manage/employees',
    tags: ['contract', 'employees', 'users', 'settings', 'services', 'overrides']
  },
  resources: {
    label: 'Resources',
    icon: 'resources',
    info: 'Add, remove and edit resources',
    path: '/manage/resources',
    tags: ['assets', 'pool', 'materials', 'rooms', 'tools', 'equipment']
  },
  resourceGroups: {
    label: 'Resource Groups',
    icon: 'resourceGroup',
    info: 'Add, remove and edit resource groups',
    path: '/manage/resource-groups',
    tags: ['assets', 'pool', 'materials', 'rooms', 'tools', 'equipment']
  },
  services: {
    label: 'Services',
    icon: 'services',
    info: 'Manage service details, employees and service defaults',
    path: '/manage/services',
    tags: ['classes', 'sessions', 'bookings', 'options']
  },
  serviceCategories: {
    label: 'Service categories',
    icon: 'serviceCategory',
    info: 'Create groups for sorting your services',
    path: '/manage/service-categories',
    tags: ['classes', 'sessions', 'bookings', 'options', 'group', 'section', 'sort', 'type']
  },
  statusTypes: {
    label: 'Appointment status types',
    icon: 'statusType',
    info: 'Add, remove and edit booking status tags',
    path: '/manage/appointment-status-types',
    tags: ['status', 'tags', 'flags']
  },
  cancellationReasons: {
    label: 'Cancellation reasons',
    icon: 'cancel',
    info: 'Add, remove and edit booking cancellation reasons',
    path: '/manage/cancellation-reasons',
    tags: ['remove', 'notes', 'flags']
  },
  products: {
    label: 'Products',
    icon: 'product',
    info: 'Manage product details',
    path: '/manage/products',
    tags: ['items', 'goods', 'merchandise', 'inventory']
  },
  productCategories: {
    label: 'Product categories',
    icon: 'productCategory',
    info: 'Create groups for sorting your products',
    path: '/manage/product-categories',
    tags: ['group', 'section', 'sort', 'type']
  },
  brands: {
    label: 'Brands',
    icon: 'brand',
    info: 'Add, remove and edit brands',
    path: '/manage/brands',
    tags: ['make', 'variety', 'manufacturer']
  },
  suppliers: {
    label: 'Suppliers',
    icon: 'supplier',
    info: 'Add, remove and edit supplier',
    path: '/manage/suppliers',
    tags: ['make', 'variety', 'manufacturer']
  },
  purchaseOrders: {
    label: 'Purchase orders',
    icon: 'purchaseOrder',
    info: 'Create and manage product purchase orders',
    path: '/manage/purchase-orders',
    tags: ['types', 'quantity', 'price', 'seller', 'document']
  },
  stockTake: {
    label: 'Stock take',
    icon: 'stockTake',
    info: 'Create and manage product inventory',
    path: '/manage/stock-take',
    tags: ['inventory', 'checking', 'counting', 'wall', 'audit']
  },
  taxes: {
    label: 'Taxes',
    icon: 'schedule',
    info: 'Add, remove and edit taxes',
    path: '/manage/taxes',
    tags: ['levy', 'tariff', 'duty', 'toll', 'sales']
  },
  customFieldsCustomer: {
    label: 'Customer',
    icon: 'schedule',
    info: 'Add, remove and edit customer custom fields',
    path: '/manage/custom-fields-customer',
    tags: ['input', 'entry', 'data', 'customer']
  },
  customFieldsReservation: {
    label: 'Appointment',
    icon: 'attendees',
    info: 'Add, remove and edit appointment custom fields',
    path: '/manage/custom-fields-reservation',
    tags: ['input', 'entry', 'data']
  },
  customFieldsReservationCustomer: {
    label: 'Group Customer',
    icon: 'schedule',
    info: 'Add, remove and edit attendee custom fields',
    path: '/manage/custom-fields-reservationCustomer',
    tags: ['input', 'entry', 'data', 'attendee', 'customer']
  },
  customFieldsSale: {
    label: 'Sale',
    icon: 'attendees',
    info: 'Add, remove and edit sale custom fields',
    path: '/manage/custom-fields-order',
    tags: ['input', 'entry', 'data', 'sale', 'order', 'purchase']
  },
  customFieldsEmployee: {
    label: 'Employee',
    icon: 'employee',
    info: 'Add, remove and edit employee custom fields',
    path: '/manage/custom-fields-employee',
    tags: ['input', 'entry', 'data', 'employee']
  },
  customFieldsService: {
    label: 'Service',
    icon: 'services',
    info: 'Add, remove and edit service custom fields',
    path: '/manage/custom-fields-service',
    tags: ['input', 'entry', 'data', 'service']
  },
  customFieldsProducts: {
    label: 'Product',
    icon: 'product',
    info: 'Add, remove and edit product custom fields',
    path: '/manage/custom-fields-product',
    tags: ['input', 'entry', 'data', 'product']
  },
  forms: {
    label: 'Forms',
    icon: 'schedule',
    info: 'Manage customer form templates & scheduling',
    path: '/manage/forms',
    tags: ['forms', 'screening', 'collect', 'survey']
  },
  customerGiftCards: {
    label: 'Customer gift cards',
    icon: 'giftCard',
    info: 'manage active customer gift gards',
    path: '/manage/customer-gift-cards',
    tags: ['present', 'voucher', 'certificate', 'purchase']
  },
  passes: {
    label: 'Passes',
    icon: 'pass',
    info: 'Manage available passes',
    path: '/manage/pass',
    tags: ['location', 'pass', 'discount', 'voucher', 'class']
  },
  customerPasses: {
    label: 'Customer passes',
    icon: 'pass',
    info: 'Manage active customer passes',
    path: '/manage/customer-pass',
    tags: ['location', 'pass', 'discount', 'voucher', 'class', 'active']
  },
  loyaltyProgram: {
    label: 'Loyalty program settings',
    icon: 'loyaltyPoints',
    info: 'Enable/disabled and set earn & spend amounts for loyalty program',
    path: '/manage/loyalty-program',
    tags: ['scheme', 'points', 'reward', 'loyalty', 'points', 'program']
  },
  activeVouchers: {
    label: 'Active promo codes',
    icon: 'pass',
    info: 'List active promo codes',
    path: '/manage/active-promo-codes',
    tags: ['discount', 'coupon', 'ticket', 'voucher', 'promotion']
  },
  expiredVouchers: {
    label: 'Expired promo codes',
    icon: 'pass',
    info: 'List expired promo codes',
    path: '/manage/expired-promo-codes',
    tags: ['discount', 'coupon', 'ticket', 'voucher', 'promotion']
  },
  discounts: {
    label: 'Discounts',
    icon: 'discount',
    info: 'Preset discount options',
    path: '/manage/discounts',
    tags: ['discount', 'coupon', 'preset', 'sale', 'order', 'purchase']
  },
  charges: {
    label: 'Charges',
    icon: 'charge',
    info: 'Preset charge options',
    path: '/manage/charges',
    tags: ['charge', 'coupon', 'preset', 'sale', 'order', 'purchase']
  },
  payments: {
    label: 'Payments',
    icon: 'payment',
    info: 'Preset payment options',
    path: '/manage/payments',
    tags: ['payments', 'coupon', 'preset', 'sale', 'order', 'purchase']
  },
  templates: {
    label: 'Templates',
    icon: 'template',
    info: 'Email notification templates',
    path: '/manage/templates',
    tags: ['templates', 'email', 'preset', 'document', 'notification']
  },
  email: {
    label: 'Email',
    icon: 'email',
    info: 'Email master template & internal email settings',
    path: '/manage/email',
    tags: ['email', 'template', 'passbook', 'gmail', 'notification']
  },
  sms: {
    label: 'SMS',
    icon: 'phone',
    info: 'SMS credit and phone number settings',
    path: '/manage/sms',
    tags: ['sms', 'number', 'system', 'country', 'credits', 'pricing']
  },
  reminders: {
    label: 'Reminders',
    icon: 'reminder',
    info: 'Appointment, review and birthday messages',
    path: '/manage/reminders',
    tags: ['appointment', 'sms', 'birthday', 'alert', 'notification']
  },
  segments: {
    label: 'Segments',
    icon: 'customerSegments',
    info: 'Customer segments',
    path: '/manage/segments',
    tags: ['customer', 'segments', 'group', 'category']
  }
}

export const pages = [
  {
    value: 'preferences',
    label: 'Preferences',
    icon: 'preferences',
    info: 'General settings',
    path: '/manage/location',
    match: '/manage/location',
    tabs: {
      location: sections.location,
      schedule: sections.schedule
    }
  },
  {
    value: 'employees',
    label: 'Employees',
    icon: 'employee',
    info: 'Bookable employee settings',
    path: '/manage/employees',
    match: '/manage/employees',
    tabs: {
      employees: sections.employees
    }
  },
  {
    label: 'Services',
    icon: 'services',
    info: 'Services, appointment statuses & cancellation reasons',
    path: '/manage/services',
    match: '/manage/services',
    value: 'services',
    tabs: {
      services: sections.services,
      serviceCategories: sections.serviceCategories,
      statusTypes: sections.statusTypes,
      cancellationReasons: sections.cancellationReasons
    }
  },
  {
    value: 'resources',
    label: 'Resources',
    icon: 'resources',
    info: 'Resources & resource groups',
    path: '/manage/resources',
    match: '/manage/resources',
    tabs: {
      resources: sections.resources,
      resourceGroups: sections.resourceGroups
      // resourceTypes: sections.resourceTypes
    }
  },
  {
    label: 'Reminders & notifications',
    icon: 'notification',
    info: 'Reminder settings & email templates',
    path: '/manage/templates',
    match: '/manage/templates',
    value: 'notification',
    tabs: {
      reminders: sections.reminders,
      email: sections.email,
      sms: sections.sms,
      templates: sections.templates
    }
  },
  {
    label: 'Forms',
    icon: 'forms',
    info: 'Customer form templates',
    path: '/manage/forms',
    match: '/manage/forms',
    value: 'forms',
    tabs: {
      forms: sections.forms
    }
  },
  {
    label: 'Products',
    icon: 'product',
    info: 'Products & inventory control',
    path: '/manage/products',
    match: '/manage/products',
    value: 'products',
    tabs: {
      products: sections.products,
      productCategories: sections.productCategories,
      brands: sections.brands,
      suppliers: sections.suppliers,
      purchaseOrders: sections.purchaseOrders,
      stockTake: sections.stockTake
    }
  },
  {
    label: 'Point of sale',
    icon: 'payment',
    info: 'Taxes, discounts, charges & custom payments',
    path: '/manage/taxes',
    match: '/manage/point-of-sale',
    value: 'taxes',
    tabs: {
      taxes: sections.taxes,
      discounts: sections.discounts,
      charges: sections.charges,
      payments: sections.payments
    }
  },
  {
    label: 'Gift Cards',
    icon: 'giftCard',
    info: 'Customer gift cards',
    path: '/manage/customer-gift-cards',
    match: '/manage/gift-cards',
    value: 'giftCards',
    tabs: {
      customerGiftCards: sections.customerGiftCards
    }
  },
  {
    label: 'Passes',
    icon: 'pass',
    info: 'Available passes & customer passes',
    path: '/manage/pass',
    match: '/manage/pass',
    value: 'passes',
    tabs: {
      passes: sections.passes,
      customerPasses: sections.customerPasses
    }
  },
  {
    label: 'Promo codes',
    icon: 'pass',
    info: 'Promotional discount codes',
    path: '/manage/active-promo-codes',
    match: '/manage/promo codes',
    value: 'promoCodes',
    tabs: {
      activeVouchers: sections.activeVouchers,
      expiredVouchers: sections.expiredVouchers
    }
  },
  {
    label: 'Loyalty Program',
    icon: 'loyaltyPoints',
    info: 'Loyalty points settings',
    path: '/manage/loyaltyProgram',
    value: 'loyaltyProgram',
    tabs: {
      loyaltyProgram: sections.loyaltyProgram
    }
  },
  {
    label: 'Segments',
    icon: 'customerSegments',
    info: 'Customer segments',
    path: '/manage/segments',
    match: '/manage/segments',
    value: 'segments',
    tabs: {
      segments: sections.segments
    }
  },
  {
    label: 'Custom Fields',
    icon: 'customFields',
    info: 'Various customisable fields',
    path: '/manage/custom-fields-customer',
    match: '/manage/custom-fields',
    value: 'customFields',
    tabs: {
      customFieldsCustomer: sections.customFieldsCustomer,
      customFieldsReservation: sections.customFieldsReservation,
      customFieldsReservationCustomer: sections.customFieldsReservationCustomer,
      customFieldsSale: sections.customFieldsSale,
      customFieldsEmployee: sections.customFieldsEmployee,
      customFieldsService: sections.customFieldsService,
      customFieldsProducts: sections.customFieldsProducts
    }
  }
]
