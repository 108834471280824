import APIObject from '../object'
import Model from '../model'
import Enum from '../enums'

export default class FormItem extends APIObject {
  objectID () {
    return this.idFormItem
  }

  relations () {
    return {
      type: { type: FormItemType },
      dataType: { type: FormItemDataType },
      options: { type: FormItemOptions }
    }
  }

  get isTypeSection () {
    return this.type === FormItemType.section
  }

  get isTypeParagraph () {
    return this.type === FormItemType.paragraph
  }

  get isTypeField () {
    return this.type === FormItemType.field
  }

  get isDataTypeText () {
    return this.dataType === FormItemDataType.text
  }

  get isDataTypeLongText () {
    return this.dataType === FormItemDataType.longtext
  }

  get isDataTypeMultiple () {
    return this.dataType === FormItemDataType.multiple
  }

  get isDataTypeBool () {
    return this.dataType === FormItemDataType.bool
  }

  get isDataTypeNumber () {
    return this.dataType === FormItemDataType.number
  }

  get isDataTypeDate () {
    return this.dataType === FormItemDataType.date
  }

  get isDataTypeSignature () {
    return this.dataType === FormItemDataType.signature
  }

  get isDataTypeMultiSelect () {
    return this.dataType === FormItemDataType.multiSelect
  }

  get isDisplayList () {
    return this.options?.display === FormItemDisplay.list
  }

  get isDisplayRadio () {
    return this.options?.display === FormItemDisplay.radio
  }
}

export class FormItemOptions extends Model {
  relations () {
    return {
      display: { type: FormItemDisplay }
    }
  }

  get isDisplayList () {
    return this.display === FormItemDisplay.list
  }

  get isDisplayRadio () {
    return this.display === FormItemDisplay.radio
  }
}

export const FormItemDisplay = new Enum({
  list: { value: 'list', description: 'Dropdown' },
  radio: { value: 'radio', description: 'Radio' }
})

export const FormItemType = new Enum({
  section: { value: 0, description: 'Section' },
  paragraph: { value: 1, description: 'Paragraph' },
  field: { value: 2, description: 'Field' }
})

export const FormItemDataType = new Enum({
  text: { value: 0, description: 'Text' },
  longtext: { value: 1, description: 'Long Text' },
  multiple: { value: 2, description: 'Select One (Dropdown/Radio List)' },
  multiSelect: { value: 8, description: 'Select Multiple (Checkbox List)' },
  bool: { value: 3, description: 'Checkbox' },
  yesNo: { value: 7, description: 'Yes / No' },
  number: { value: 4, description: 'Number' },
  date: { value: 5, description: 'Date' },
  signature: { value: 6, description: 'Signature' },
})
