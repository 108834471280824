<template>
  <portal to="dialog-container">
    <transition name="fade">
      <div v-if="active" class="w-screen h-full absolute z-80000 left-0 bottom-0 flex flex-col items-end justify-end pointer-events-none">
        <div class="mx-auto m-6 w-full max-w-sm z-20 pointer-events-auto">
          <div class="px-3 py-4 bg-secondary rounded-xl shadow-lg bg-opacity-95 mx-3 md:-mx-5 flex items-center justify-between">
            <div class="flex items-center mx-2">
              <template v-if="loading">
                <o-loader
                  :scale="0.3"
                  class="h-8 w-8 mx-auto mr-3"
                />
                <p class="text-white text-sm font-medium leading-none mt-px">
                  Loading...
                </p>
              </template>
              <template v-else>
                <o-icon :size="18" icon="warning" class="mr-3 text-white opacity-85" />
                <p class="text-white text-sm font-medium leading-none mt-px">
                  {{ message }}
                </p>
              </template>
            </div>
            <div class="flex items-center space-x-1">
              <slot>
                <o-button variant="light" translucent class="mr-1" @click="$emit('cancel')">
                  Cancel
                </o-button>
                <o-button variant="light" translucent icon="save" class="mr-2" @click="$emit('save')">
                  Save Changes
                </o-button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  name: 'OActionBar',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: 'Unsaved changes...'
    }
  }
}
</script>
